var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "grid",
          attrs: {
            title: "Spare Part 목록",
            isTitle: true,
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            hideBottom: true,
            isExcelDown: false,
            filtering: false,
            columnSetting: false,
            gridHeightAuto: true,
            usePaging: false,
          },
          on: { linkClick: _vm.linkClick },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [_c("q-btn-group", { attrs: { outline: "" } })],
            1
          ),
        ],
        2
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }